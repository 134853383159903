/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:07e328e1-7f1a-4da8-93ec-415949078a1c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IOUnv5HCq",
    "aws_user_pools_web_client_id": "7f5mojceb740pkcpapeg8f5jul",
    "oauth": {}
};


export default awsmobile;
